import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import SearchEngineOptimization from "../components/SearchEngineOptimization";
import PostCard from "../components/PostCard"
import ListPagenation from "../components/ListPagenation";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  desc: {
    paddingLeft: "0.5rem"
  }
}))

const BlogList = ({ data, location, pageContext }) => {
  const classes = useStyles()
  const siteMetadata = pageContext.siteMetadata
  const categories = pageContext.categories
  // const catBlogPosts = data.allMicrocmsBlog.edges
  const _catBlogPosts = pageContext.catBlogPosts
  const catBlogPosts = _catBlogPosts.slice(pageContext.skip, pageContext.skip + pageContext.limit)

  return (
    <Layout
      siteMetadata={siteMetadata}
      categories={categories}
    >
      <SearchEngineOptimization
        pagetitle={`「${pageContext.categoryTitle}」カテゴリー 一覧ページ(${pageContext.lastPageNo}ページ中${pageContext.lastPageNo}ページ目)`}
        pagedesc={`「${pageContext.categoryTitle}」カテゴリー 一覧ページ(${pageContext.lastPageNo}ページ中${pageContext.lastPageNo}ページ目) ${pageContext.categoryDesc}`}
        pagepath={location.pathname}
      />

      <Container>
        <Typography variant="h6" style={{ fontWeight: `bold` }}>{`${pageContext.categoryTitle}`}</Typography>
        <Typography className={classes.desc} variant="body2">{pageContext.categoryDesc}</Typography>

        <hr />

        <Grid container spacing={4}>
          {catBlogPosts.map(edge => (
            <PostCard edge={edge} categorySlug={pageContext.categorySlug} key={edge.node.id} />
          ))}
          {catBlogPosts.length <= 0 &&
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1">まだなにも投稿されていません。</Typography>
            </Grid>
          }
        </Grid>
        <ListPagenation category={pageContext.categorySlug} pageNo={pageContext.pageNo} lastPageNo={pageContext.lastPageNo} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
query ($skip: Int!, $limit: Int!, $categorySlug: String!) {
  allMicrocmsBlog(
    sort: {fields: publishedAt, order: DESC}
    skip: $skip
    limit: $limit
    filter: {category: {elemMatch: {categorySlug: {in: [$categorySlug]}}}}
  ) {
    edges {
      node {
        id
        slug
        title
        eyecatch {
          url
        }
        publishedAt
        fields {
          hasScheduledAt
          scheduledAt
          blogDescription
        }
      }
    }
  }
}
`

export default BlogList