
import * as React from "react"

import Link from "../components/Link"
import { Grid } from "@material-ui/core";
//import { CardHeader} from "@material-ui/core";
import { Card, CardContent, CardActions } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Imgix from "react-imgix";
import moment from "moment"


const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: `relative`,
    },
    cardFigure: {
        height: '280px',
        margin: 0,
    },
    cardImg: {
        width: `100%`,
        height: `280px`,
        objectFit: `cover`,
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
        height: `350px`,
    },

    cardContent: {
        flexGrow: 1,
    },
    cardActions: {
        // position:`absolute`,
        // left: theme.spacing(1),
        // bottom:theme.spacing(1),
    },

}))

const PostCard = ({ edge, categorySlug }) => {
    const classes = useStyles()

    return (
        <Grid item xs={12} sm={12} md={6} lg={4}>
            <Card className={classes.card}>
                <figure className={classes.cardFigure}>
                    <Imgix
                        className={classes.cardImg}
                        src={edge.node.eyecatch.url}
                        sizes={`100vw`}
                        htmlAttributes={{
                            alt: `記事「${edge.node.title}」のメイン画像`
                        }}
                    />
                </figure>
                <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {edge.node.title}
                    </Typography>
                    <Typography gutterBottom variant="body1">
                        {edge.node.fields.blogDescription}
                    </Typography>

                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Link to={`/${categorySlug}/${edge.node.slug || edge.node.id}`}>
                        <Button color="primary" variant="contained">読む / Read more</Button>
                    </Link>
                </CardActions>
                {false &&
                    <Grid style={{ textAlign: `center` }}>
                        <Typography gutterBottom variant="body2">
                            <time dateTime={edge.node.publishedAt}>
                                投稿: {moment(edge.node.publishedAt).format('YYYY年MM月DD日 HH:mm')}
                            </time>
                            {
                                edge.node.publishedAt !== edge.node.revisedAt &&
                                <time dateTime={edge.node.revisedAt}>
                                    {` / 更新: ${moment(edge.node.revisedAt).format('YYYY年MM月DD日 HH:mm')}`}
                                </time>
                            }
                        </Typography>
                    </Grid>
                }
            </Card>
        </Grid>
    )
}

export default PostCard
